@import "~@fontsource/material-icons/index.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import "common";
@import "theme";
@import "calendar";

html, body {
  height: 100% !important;
}

body {
  margin: 0;
  //  font-family: Roboto, "Helvetica Neue", sans-serif;
}
